/* VITE processes this file */

/* Load CSS for Vite to process */
import "@css/screen-core.pcss";
import "@css/screen-animations.pcss";
import "@css/screen-design.pcss";
import "@css/screen-forms.pcss";

/* Load JS for Vite to process as needed for THIS file (likely... never)
import "@js/parts/lazyloading";
import "@js/parts/fetch-wrapper"; */

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log("Hot Module Reload happening");
	});
}

console.log('app.js says hello.');
